export const menuItems = [
  {
    name: "demos",
    label: "freelance services",
    items: [
      { name: "online-growth-manager", label: "online growth manager" },
      { name: "product-manager", label: "product manager" },
      { name: "web-developer", label: "web developer" },
      
    ],
  },
];
